import React, { useState, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Grid, TextField, IconButton } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { useDispatch } from 'react-redux';
import { removeComment } from 'slices/goal/comment/action';
import moment from 'moment';
import { DeleteConfirmation } from 'common/components';

import { GoalComment } from 'types/goal';
import { JournalComment } from 'types/journey';
import CommentReply from '../CommentReply';

// import { addNewComment } from 'slices/goal/comment/action';

const goalComment = require('slices/goal/comment/action');
const journalComment = require('slices/journey/comment/action');

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '20px 10px',
    border: '1px solid #73BA9B',
    boxSizing: 'border-box',
    borderRadius: '13px',
    marginTop: '18px',
    backgroundColor: '#FFFFFF'
  },
  avatar: {
    width: 50,
    height: 50
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '127.69%',
    color: '#B3B3B3'
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#73BA9B'
  },
  replyTextField: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    background: 'white',
    borderRadius: '8px',
    padding: 8,

  },
  replyOuterBox: {
    background: '#e1e1e1',
    padding: 17,
    borderRadius: 8,
    marginTop: 13
  },
  submitButtonBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: 10
  },
  submitButton: {
    color: '#FFFFFF',
    border: 'none',
    width: 100,
    cursor: 'pointer',
    height: 35,
    fontSize: 14,
    boxSizing: 'border-box',
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    fontWeight: 700,
    borderRadius: '25px',
    backgroundColor: '#003E1F'
  },
  personName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 15,
    color: '#37474F'
  },
  message: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    color: '#323F45'
  },
  placeholder: {
    '&::placeholder': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#B3B3B3',
      fontSize: 14
    },
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
  }
}));

type Props = {
  commentId: string;
  id: string;
  type: string;
  name: string;
  message: string;
  favorite?: boolean;
  date: string;
  image: string;
  children: (GoalComment | JournalComment)[];
};

const Comment: React.FC<Props> = ({
  commentId,
  id,
  type,
  name,
  message,
  date,
  favorite = true,
  image,
  children
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDeleteEvent = () => {
    dispatch(removeComment(id, commentId));
  };

  /** Dialog */
  const [open, setOpen] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  // To reply on a comment
  const [replyBox, setReplyBox] = useState(false);

  function openReplyBox() {
    if (replyBox) {
      setReplyBox(false);
    } else {
      setReplyBox(true);
    }
  }

  function closeReplyBox() {
    setReplyBox(false);
  }

  const [new_message, setMessage] = useState('');

  const handleFieldChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMessage(event.target.value);
  };

  const handleCommentSubmit = () => {
    if (type === 'Goal') {
      if (new_message.length > 1) {
        dispatch(
          goalComment.addNewComment(
            id,
            new_message,
            sessionStorage.getItem('FirstName')!,
            commentId
          )
        );
        setMessage('');
        closeReplyBox();
      }
    } else {
      if (new_message.length > 1) {
        dispatch(
          journalComment.addNewComment(
            id,
            new_message,
            sessionStorage.getItem('FirstName')!,
            commentId
          )
        );
        setMessage('');
        closeReplyBox();
      }
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseBox = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={2}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}>
            {image != undefined ? (
              <Avatar
                alt=""
                className={classes.avatar}
                src={image}
              />
            ) : (
                <Avatar alt="" className={classes.avatar} src={sessionStorage.getItem('Avatar')!} />
              )}
          </div>
        </Grid>
        <Grid item xs={9}>
          <div>
            {name ?
              (<span className={classes.personName}>{name}</span>)
              :
              (<span className={classes.personName}>{sessionStorage.getItem('FirstName')}</span>)
            }
            {/* <span className={classes.personName}>{name}</span> */}
          </div>
          <div style={{ overflowWrap: 'break-word', marginTop: 3 }}>
            <span className={classes.message}>{message}</span>
          </div>
        </Grid>
        <Grid item xs={1}>
          <IconButton edge="end" color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <MoreIcon style={{ color: '#B3B3B3' }} />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseBox}
          >
            <MenuItem onClick={() => { handleClickOpen(); handleCloseBox() }}>
              Delete
              </MenuItem>
          </Menu>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <div>
            <span className={classes.text}>{moment(date).format('LLLL')}</span>
          </div>
        </Grid>
        <Grid item xs={2}>
          <p className={classes.text} style={{ paddingRight: 6, cursor: 'pointer', color: '#73ba9b', display: 'flex', flexDirection: 'row-reverse' }} onClick={openReplyBox}>
            <b>Reply</b>
          </p>
        </Grid>
      </Grid>
      {open && (
        <DeleteConfirmation
          open={open}
          close={handleClose}
          action={handleDeleteEvent}
          donRedirect>
          <span className={classes.title}>
            Are you sure you want to
            <br />
            delete this comment?
          </span>
        </DeleteConfirmation>
      )}
      {replyBox && (
        <Grid container>
          <Grid item xs={2} />
          <Grid item xs={10}>
            <div className={classes.replyOuterBox}>
              <TextField
                placeholder="Write a reply..."
                fullWidth
                // multiline
                value={new_message}
                autoComplete="off"
                // rows="1"
                className={classes.replyTextField}
                InputProps={{ disableUnderline: true, classes: { input: classes.placeholder } }}
                onChange={event => handleFieldChange(event)}
              />
              <div className={classes.submitButtonBox}>
                <button className={classes.submitButton} onClick={handleCommentSubmit}>Submit</button>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      <Grid container>
        {children.map(child => {
          return (
            <Grid item key={child.Id} xs={12}>
              <Grid container>
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                  <CommentReply
                    commentId={child.Id}
                    id={child.Id}
                    name={child.PersonName}
                    message={child.Message}
                    date={child.CreatedOnDate}
                    image={sessionStorage.getItem('Avatar')!}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Comment;
