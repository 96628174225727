import { AppThunk } from 'store';
import axios from 'common/utils/axios';
import authentication from '@kdpw/msal-b2c-react';
import { fetchPendingContacts, fetchConnectRequests } from './invitationSlice';
import { Invitation, Network } from 'types/network';
import { stopLoading, startLoading, showError, hideError } from '../networkSlice';
import {
  callNetworkContactListApi,
  callNetworkContactCreateApi,
  fetchNetworks,
  callRecoveryPlanShareCreateApi
} from '../action';
import { updateNetwork } from 'slices/journey/journeySlice';

//Hammad Tahir - create sahres after user selection - 290921 - Start

// import { startSharingAllJournals } from '../access/journals/action';
// import { startSharingAllGoals } from '../access/goals/action';
// import { shareSafetyPlan } from '../access/safetyplan/action';
// import { startSharingContact } from '../access/contacts/action';
// import { callMyStoryReadApi, fetchStoryData } from 'slices/story/action'; //Hammad Tahir - invitationFix - 050821 - added fetchStoryData
// import { startSharingStory } from '../access/mystory/action';
// import { exception } from 'console';
// import { resolve } from 'path';

// //Hammad Tahir - invitationFix - 050821 - Start
// import { fetchGoals } from 'slices/goal/action';
// import { fetchJournalsData } from 'slices/journey/action';
// import { fetchSafetyPlanServices } from 'slices/safety/action';
// //Hammad Tahir - invitationFix - End

//Hammad Tahir - create sahres after user selection - End
//** ASYNC FUNCS */
export const fetchPendingContactFromInvitation = (): AppThunk => async dispatch => {
  try {
    // const contacts = await callNetworkContactListApi(); //Hammad Tahir 061021 - add/edit network contacts - fetch and set all sent invitations

    const invitations = await callInvitationListApi();
    //Hammad Tahir 061021 - add/edit network contacts - fetch and set all sent invitations
    // const pendingContacts = invitations.filter(
    //   item =>
    //     !item.Name &&
    //     !item.AcceptedOn &&
    //     !contacts.find(contact => contact.Email === item.EmailAddress)
    // );

    // const sortedContacts = pendingContacts.sort(
    //   (a, b) =>
    //     new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime()
    // );

    //Hammad Tahir 061021 - add/edit network contacts - fetch and set all sent invitations
    const sortedContacts = invitations.sort(
      (a, b) =>
        new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime()
    );

    dispatch(fetchPendingContacts({ pendingContacts: sortedContacts }));
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

export const fetchConnectionRequests = (): AppThunk => async dispatch => {
  try {
    const requests = await callInvitationReadApi();
    // const newConnectionRequests = requests.filter(item => !item.AcceptedOn);

    const connectRequests = requests.sort(
      (a, b) =>
        new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime()
    );

    dispatch(fetchConnectRequests({ connectRequests }));
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

export const acceptConnectRequest = (
  invitationId: string,
  email: string,
  name: string,
  close: () => void,
  accountType: 'Person' | 'Organisation',
  history: any
): AppThunk => async (dispatch, getState) => { //Hammad Tahir T138 131021 - [Consumer] Repeated pending invitations - added getState
  try {
    dispatch(startLoading());

    // Caroline Bezzina 23/09/2020 Task J21 - The suggested contact made by SP has no name
    let network: Network = {
      Id: '',
      UserId: sessionStorage.getItem('UserId')!,
      Name: name,
      ContactName: name,
      Email: email,
      Phone: '',
      CallForSupport: false,
      Address: '',
      Order: 0,
      Type: accountType,
      Relationship: '',
      Image: '',
      ImageType: '',
      ImageUrl: '',
      ContactAddress: '',
      ContactEmail: email,
      ContactPhone: ''
    };

    const response: string = await callNetworkContactCreateApi(network);

    const responseArray = response.split(' ');
    const updatedNetwork: Network = {
      ...network,
      Id: responseArray[5]
    };

    //Hammad Tahir T138 131021 - [Consumer] Repeated pending invitations - Start
    const contactRecievedInvites = await callInvitationReadApi().then(res => 
      res.filter(item => item.InviterUserEmailAddress === email
    ));

    const contactSentInvites = await callInvitationListApi().then(res => 
      res.filter(item => item.EmailAddress === email
    ));

    await callInvitationConsumerAcceptAndNotify(invitationId).then(res => {
      contactRecievedInvites.concat(contactSentInvites).map(async item => 
        await callInvitationDelete(item.InvitationId)
      );
    });
    // await callInvitationDelete(invitationId);
    //Hammad Tahir T138 131021 - [Consumer] Repeated pending invitations - End
    await dispatch(fetchConnectionRequests());
    

    sessionStorage.removeItem('invitationId');
    sessionStorage.removeItem('inviterEmail');
    sessionStorage.removeItem('inviterName');

    //Hammad Tahir 061021 - add/edit network contacts - start sharing recovery plan after accepting connection request
    await callRecoveryPlanShareCreateApi(updatedNetwork.Id, updatedNetwork.Name)

    //Hammad Tahir - create sahres after user selection - 290921 - Start

    // // sharing journals
    // await dispatch(fetchJournalsData()); //Hammad Tahir - invitationFix - 050821 - Addition
    // await dispatch(startSharingAllJournals(updatedNetwork));

    // // sharing goals
    // await dispatch(fetchGoals()); //Hammad Tahir - invitationFix - 050821 - Addition
    // await dispatch(startSharingAllGoals(updatedNetwork));
    
    // // sharing my story
    // const story = await callMyStoryReadApi();
    // dispatch(startSharingStory(story.MyStoryId, updatedNetwork));

    // // sharing safety plan
    // await dispatch(fetchSafetyPlanServices()); //Hammad Tahir - invitationFix - 050821 - Addition
    // await dispatch(shareSafetyPlan(updatedNetwork));

    // // sharing contacts
    // await dispatch(startSharingContact(updatedNetwork));

    // await dispatch(fetchStoryData()); //Hammad Tahir - invitationFix - 050821 - Addition

    //Hammad Tahir - create sahres after user selection - End
    await dispatch(fetchNetworks());

    dispatch(stopLoading());
    close();

    if (responseArray.length > 5) {
      history.replace(
        `/networks/${accountType === 'Person' ? 'people' : 'services'}/${updatedNetwork.Id
        }/edit`
      );
    }
  } catch (err) {
    history.push('/goals/current');//Jorge Avendano J18 200917 Avoid sign in loop
    // dispatch(failed(err.toString()));
  }
};

export const sendInvitation = (invitation: Invitation): AppThunk => async dispatch => {
  try {
    dispatch(hideError()); // Prafful Jagtap - Issue J14 - Display error message on fail API 
    dispatch(startLoading());
    await callInvitationCreateApi(invitation).catch(err => { // Prafful Jagtap - Issue J14 - Display error message on fail API 
      throw err;
    });
    await dispatch(fetchPendingContactFromInvitation());
    dispatch(stopLoading());
  } catch (err) {
    dispatch(showError()) // Prafful Jagtap - Issue J14 - Display error message on fail API 
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

export const acceptInvitation = (id: string): AppThunk => async dispatch => {
  try {
    await callInvitationConsumerAcceptAndNotify(id);
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

export const deleteInvitation = (id: string): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());
    await callInvitationDelete(id);
    await dispatch(fetchPendingContactFromInvitation());
    await dispatch(fetchConnectionRequests());

    dispatch(stopLoading());
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

//** API FUNCS */
export const callInvitationListApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  return axios.get('/Invitation/List').then(response => {
    const invitations: Invitation[] = JSON.parse(JSON.stringify(response.data));
    return invitations;
  });
};

export const callInvitationCreateApi = (invitation: Invitation) => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  return axios.post('/Invitation/Create', invitation);
};

// const callInvitationAccept = (id: string) => {
//   axios.defaults.headers.common['Authorization'] =
//     'Bearer ' + authentication.getAccessToken();

//   return axios.post(`/Invitation/Accept/${id}`);
// };

export const callInvitationDelete = (id: string) => { //Hammad Tahir - invitationFix - 050821 - Export function
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  return axios.delete(`/Invitation/Delete/${id}`);
};

export const callInvitationReadApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  return axios.get('/Invitation/Read').then(response => {
    const invitations: Invitation[] = JSON.parse(JSON.stringify(response.data));
    return invitations;
  });
};

const callInvitationConsumerAcceptAndNotify = (id: string) => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  return axios.post(`/Invitation/ConsumerAcceptAndNotify/${id}`);
};

//Hammad Tahir 061021 - add/edit network contacts - update sent invitation
export const callInvitationUpdateApi= (invitation: Invitation ) => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  return axios.post(`/Invitation/Update/${invitation.InvitationId}`, invitation)
};