import React, { useState, Suspense, useEffect } from 'react';
import useRouter from 'common/utils/useRouter';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';

import { makeStyles, useTheme } from '@material-ui/styles';
import {
  LinearProgress,
  Theme,
  Hidden,
  Dialog,
  DialogContent,
  useMediaQuery
} from '@material-ui/core';

import {
  GoalTopBar,
  JourneyTopBar,
  NetworkTopBar,
  SafetyTopBar,
  StoryTopBar,
  DefaultTopBar,
  ProfileTopBar,
  AboutTopBar
} from './TopBars';
import { NavBar } from '../components';
import clsx from 'clsx';
import { LogoutPopup } from 'common/components';
import { callProfileReadApi } from 'slices/auth/action';
import { ClimbingBoxLoader } from 'react-spinners';
import { css } from '@emotion/core';
//Hammad Tahir T169 071021  - "Add to Emergency/Safety Plan" switch not reflecting straight away
//Load emergency networks if empty
import { fetchEmergencyContacts } from 'slices/safety/support/action';
import { useDispatch, useSelector } from 'react-redux';
import { Network } from 'types/network';
import { RootState } from 'reducer';

const override = css`
  display: block;
  margin: 0 auto;
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    overflow: 'hidden'
  },
  mainContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  navBar: {
    zIndex: 3,
    width: 150,
    minWidth: 150,
    flex: '0 0 auto'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
    backgroundColor: '#FFFFFF'
  },
  contentWithBackground: {
    overflowY: 'auto',
    flex: '1 1 auto',
    backgroundColor: '#D5F2E3'
  },
  topBar: {
    zIndex: 2,
    position: 'relative',
    paddingTop: '20px'
  },
  storyTopBar: {
    zIndex: 2,
    position: 'absolute',
    top: '0',
    paddingTop: '20px',
    background: 'rgba(0, 0, 0, 0.5)'
  },
  aboutTopBar: {
    zIndex: 4
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#D5F2E3'
  }
}));

const BaseLayoutWithNavbar: React.FC<RouteConfigComponentProps> = ({
  route
}) => {
  const { history } = useRouter();
  const classes = useStyles();
  const dispatch = useDispatch();

  //Hammad Tahir T169 071021  - "Add to Emergency/Safety Plan" switch not reflecting straight away
  //get emergency networks to check if empty
  const emergencyNetworks: Network[] = useSelector(
    (state: RootState) => state.safetyRoot.support.emergencyNetworks
  );

  //Hammad Tahir - switchAccountFix - 190721 - Start
  /** Theme */
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up('xs'));

  const [profileRead, setProfileRead] = useState(false);

  const loginCheck = async () => {
    if (sessionStorage.getItem('LoggedInAs') === null) {
      await callProfileReadApi().then(() => setProfileRead(true));
    }
    if (sessionStorage.getItem('LoggedInAs') !== 'Patient') {
      history.replace('/errors/error-403');
    }
  };
  //Hammad Tahir - switchAccountFix - 190721 - Start

  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [active, setActive] = useState(0);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  let topBar = null;
  let menu = '';
  if (
    history.location.pathname === '/goals/current' ||
    history.location.pathname === '/goals/completed'
  ) {
    topBar = (
      <Hidden lgUp>
        <GoalTopBar
          className={classes.topBar}
          onOpenNavBarMobile={handleNavBarMobileOpen}
        />
      </Hidden>
    );
  } else if (
    history.location.pathname === '/journeys/all' ||
    history.location.pathname === '/journeys/journals' ||
    history.location.pathname === '/journeys/summary' ||
    history.location.pathname === '/journeys/calendar'
  ) {
    topBar = (
      <Hidden lgUp>
        <JourneyTopBar
          className={classes.topBar}
          onOpenNavBarMobile={handleNavBarMobileOpen}
        />
      </Hidden>
    );
  } else if (
    history.location.pathname === '/networks/people' ||
    history.location.pathname === '/networks/services'
  ) {
    menu = 'networks';
    topBar = (
      <Hidden lgUp>
        <NetworkTopBar
          className={classes.topBar}
          onOpenNavBarMobile={handleNavBarMobileOpen}
        />
      </Hidden>
    );
  } else if (history.location.pathname === '/safety') {
    topBar = (
      <Hidden lgUp>
        <SafetyTopBar
          className={classes.topBar}
          onOpenNavBarMobile={handleNavBarMobileOpen}
        />
      </Hidden>
    );
  } else if (history.location.pathname === '/story') {
    topBar = (
      <Hidden lgUp>
        <StoryTopBar
          className={classes.storyTopBar}
          onOpenNavBarMobile={handleNavBarMobileOpen}
        />
      </Hidden>
    );
  } else if (history.location.pathname === '/notification') {
    menu = 'notification';
    topBar = (
      <DefaultTopBar
        title="Notifications"
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
    );
  } else if (
    history.location.pathname ===
    `/profile/${sessionStorage.getItem('FirstName')}`
  ) {
    topBar = (
      <ProfileTopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
    );
  } else if (history.location.pathname === '/export') {
    menu = 'export';
    topBar = (
      <DefaultTopBar
        title="Export PDF"
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
    );
  } else if (history.location.pathname === '/settings') {
    menu = 'settings';
    topBar = (
      <DefaultTopBar
        title="Settings"
        className={`${classes.topBar} ${classes.aboutTopBar}`}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
    );
    //Smarth Bajaj Changes
  } else if (history.location.pathname === '/about') {
    menu = 'about';
    topBar = (
      <AboutTopBar
        title="About"
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
    );
  }

  //Hammad Tahir - switchAccountFix - 190721 - Start
  useEffect(() => {
    loginCheck();
  }, [profileRead]);
  //Hammad Tahir - switchAccountFix - 190721 - End

  //Hammad Tahir T169 071021  - "Add to Emergency/Safety Plan" switch not reflecting straight away
  //Load emergency contacts if empty
  useEffect(() => {
    if(emergencyNetworks.length === 0){
      dispatch(fetchEmergencyContacts());
    }
  }, [dispatch]);

  return (
    <>
      {/* Hammad Tahir - switchAccountFix - 190721 - Show climber box while reading profile */}
      {sessionStorage.getItem('LoggedInAs') === null ? (
        <main className={classes.content}>
          <Dialog open keepMounted fullScreen={fullScreen}>
            <DialogContent className={classes.loadingContainer}>
              <ClimbingBoxLoader
                css={override}
                size={20}
                color={'rgb(0, 62, 31)'}
                loading
              />
            </DialogContent>
          </Dialog>
        </main>
      ) : (
        <div className={classes.root}>
          <NavBar
            className={classes.navBar}
            onMobileClose={handleNavBarMobileClose}
            openMobile={openNavBarMobile}
          />
          <div className={classes.mainContainer}>
            {topBar}
            <div className={classes.container}>
              <main
                className={clsx(
                  classes.content,
                  menu !== '' && classes.contentWithBackground
                )}>
                <Suspense fallback={<LinearProgress />}>
                  {route && renderRoutes(route.routes)}
                </Suspense>
              </main>
            </div>
          </div>
        </div>
      )}
      <LogoutPopup />
    </>
  );
};

export default BaseLayoutWithNavbar;
