/* eslint-disable react/display-name */
import React, { forwardRef, useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  ListItem,
  Button,
  colors,
  Theme,
  ButtonProps
} from '@material-ui/core';
// Jorge Avendano T29 110820 get invitations begin
import Badge from '@material-ui/core/Badge';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducer';
import { Invitation } from 'types/network';
import { fetchConnectionRequests } from 'slices/network/invitation/action';
// Jorge Avendano T29 110820 end

//Jorge Avendano T8 260820 Implementation of push notifications through the browser begin
import addNotification from 'react-push-notification';
//Jorge Avendano T8 end

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: colors.blueGrey[800],
    padding: '14px 8px',
    justifyContent: 'center',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '&:hover': {
      color: '#C57D7D'
    }
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  active: {
    //color: theme.palette.primary.main,
    color: '#000',
    backgroundColor: 'white',
    fontWeight: theme.typography.fontWeightMedium
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  buttonText: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '15px',
    textAlign: 'center',
    // color: '#D5F2E3',
    color: '#692B40',
    marginTop: '1px',
    textTransform: 'uppercase'
  },
  buttonTextActive: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '15px',
    textAlign: 'center',
    color: '#003E1F',
    marginTop: '5px',
    textTransform: 'uppercase'
  },
  customBadge: {// Jorge Avendano T29 110820 dot style
    backgroundColor: "#F79221",
    padding: "4px",
    margin: "-10px",
    paddingBottom: "4px",
    fontSize: "16px"
  }
}));

type NavProps = {
  activeClassName: string;
  className: string;
  component: React.FC<any>;
  exact: boolean;
  to: string;
};

type Props = {
  depth: number;
  href: string;
  icon: string;
  iconActive: string;
  title: string;
  badge: string;// Jorge Avendano T29 110820 defines if the item will have notifications
};

const CustomRouterLink = forwardRef<HTMLDivElement, NavProps>((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const MyExtendedButton: React.FC<ButtonProps & NavProps> = (
  props: ButtonProps & NavProps
) => {
  const { children, ...buttonProps } = props;
  return <Button {...buttonProps}>{children}</Button>;
};

const NavigationListItem: React.FC<Props> = ({
  title,
  href,
  depth,
  iconActive,
  badge// Jorge Avendano T29 110820 defines if the item will have notifications
}) => {
  const classes = useStyles();

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = {
    paddingLeft
  };

  // Jorge Avendano T29 110820 get invitations begin
  const dispatch = useDispatch();
  const connectRequests: Invitation[] = useSelector(
    (state: RootState) => state.networkRoot.invitation.connectRequests
  );

  useEffect(() => {
    if (badge == 'true') {
      dispatch(fetchConnectionRequests());
      if (connectRequests.length <= 0)
        badge = 'false';
    }
  }, [dispatch]);

  if (connectRequests.length <= 0)
    badge = 'false';
  // Jorge Avendano T29 110820 get invitations end

  //Jorge Avendano T8 260820 Implementation of push notifications through the browser begin
  
  let shownNotifications = '';
  if (sessionStorage.getItem('shownNotifications') != null)
    shownNotifications = JSON.parse(sessionStorage.getItem('shownNotifications')!);

  if (connectRequests.length > 0) {
    for (let item of connectRequests) {
      if (!shownNotifications.includes(item.InvitationId)) {
        addNotification({
          title: 'Invitation',
          message: item.Name + ' invited you to connect on Journi.',
          duration: 10000,
          icon: 'https://recoveryplanstorage.blob.core.windows.net/assets/Invite.png',
          //theme: 'darkblue',
          native: true // when using native, your OS will handle theming.
        });
        shownNotifications = shownNotifications + ',' + item.InvitationId;
      }
    }
    sessionStorage.setItem('shownNotifications', JSON.stringify(shownNotifications));
  }
  //Jorge Avendano T8 260820 end

  return (
    <ListItem className={classes.itemLeaf} disableGutters>
      <MyExtendedButton
        activeClassName={classes.active}
        className={classes.buttonLeaf}
        component={CustomRouterLink}
        exact
        style={style}
        to={href}>
        <div className={classes.buttonContent}>
          <>
            {/* Jorge Avendano T29 110820 add notification dot*/}
            <Badge
              badgeContent={connectRequests.length}
              invisible={(badge == 'false' ? true : false)}
              color="error"
              overlap="circle"
              classes={{ badge: classes.customBadge }}>
              <img src={iconActive} alt="" />
            </Badge>
            <span className={classes.buttonTextActive}>{title}</span>
          </>
        </div>
      </MyExtendedButton>
    </ListItem>
  );
};

export default NavigationListItem;
