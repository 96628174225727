//Smarth Bajaj Changes
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  IconButton,
  Toolbar,
  Hidden,
  Typography
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import Slide from '@material-ui/core/Slide';
import { Emergency } from 'common/components';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import { NavigateNext } from '@material-ui/icons';
import Iframe from 'react-iframe';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { ArrowBackIos } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFFFFF'
  },
  textColor: {
    color: '#FFFFFF'
  },
  icon: {
    color: '#37474F',
    fontSize: '30px'
  },
  contentRoot: {
    backgroundColor: '#D5F2E3',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden'
  },
  navBar: {
    backgroundColor: '#FFFFFF',
    minHeight: '64px',
    zIndex: 1
  },
  contentContainer: {
    marginTop: '3em',
    marginLeft: '8em',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1em',
      marginRight: '1em',
      marginTop: '1em'
    },
    [theme.breakpoints.down('xs')]: {
      width: '95vw'
    }
  },
  displayContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '0.75em',
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px'
  },
  link: {
    textDecoration: 'none',
    color: '#73BA9B',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '127.69%'
  },
  textContainer: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '48px',
    lineHeight: '58px',
    color: '#37474F'
    // textAlign: 'center'
  },
  paperContainer: {
    // width: '30em',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '1em',
      marginRight: '1em'
      // width: '28em'
    }
  },
  itemContainer: {
    marginRight: 0,
    marginLeft: 0
  },
  jiembaHeading: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '37px',
    color: '#37474F'
  },
  jiembaSubHeading: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '127.69%',
    color: '#37474F',
    marginTop: '0.25em',
    display: 'flex',
    justifyContent: 'flex-start',
    borderBottom: '3px solid #73BA9B',
    alignItems: 'center',
    width: '11em'
    //
  },
  jiembaTwoSubHeading: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '127.69%',
    color: '#37474F',
    marginTop: '0.5em',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '26em',
    [theme.breakpoints.down('xs')]: {
      width: '20em'
    }
  },
  topContainer: {
    marginTop: '1.5em',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5em'
    }
  },
  //Smarth Bajaj - Task #24 - 02/Sept/2020
  frameContainer: {
    height: '100vh'
  },
  iconContainer: {
    display: 'flex'
  }
}));

type Props = {
  className: string;
  title: string;
  onOpenNavBarMobile: () => void;
};

const TopBar: React.FC<Props> = ({ onOpenNavBarMobile, className, title }) => {
  const classes = useStyles();
  const theme = useTheme();

  /** Dialog */
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(0);
  const matchesMD = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    setActive(0);
  }, []);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const url = 'https://journi.aihealth.tech/wellbeing';

  const MainPage = (
    <Slide direction="right" mountOnEnter unmountOnExit in={active === 0}>
      <div className={classes.contentRoot}>
        <Grid container justify="flex-start">
          <Grid item>
            <Grid
              container
              className={classes.contentContainer}
              direction="column">
              <Grid
                item
                className={`${classes.itemContainer} ${classes.topContainer}`}>
                <Paper
                  className={classes.paperContainer}
                  style={{
                    padding: '0.15em',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)'
                  }}>
                  <div className={classes.displayContainer}>
                    <img
                      style={{ marginRight: '0.75em' }}
                      src="/images/about/star.png"
                      alt="Use Jiemba"
                    />
                    <h4>How to use Journi?</h4>
                    <IconButton
                      onClick={() => setActive(1)}
                      style={{ marginLeft: 'auto' }}>
                      <NavigateNext
                        style={{ fill: '#73BA9B' }}
                        fontSize="default"
                      />
                    </IconButton>
                  </div>
                </Paper>
              </Grid>
              <Grid
                className={classes.itemContainer}
                item
                style={{ marginTop: '1em' }}>
                <Paper
                  className={classes.paperContainer}
                  style={{
                    padding: '0.15em',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)'
                  }}>
                  <div className={classes.displayContainer}>
                    <img
                      style={{ marginRight: '0.75em' }}
                      src="/images/about/questions.png"
                      alt="Use Jiemba"
                    />
                    <h4>Frequently asked questions</h4>
                    <IconButton
                      onClick={() => setActive(2)}
                      style={{ marginLeft: 'auto' }}>
                      <NavigateNext
                        style={{ fill: '#73BA9B' }}
                        fontSize="default"
                      />
                    </IconButton>
                  </div>
                </Paper>
              </Grid>
              <Grid
                className={classes.itemContainer}
                item
                style={{ marginTop: '1em' }}>
                <Paper
                  className={classes.paperContainer}
                  style={{
                    padding: '0.15em',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)'
                  }}>
                  <div className={classes.displayContainer}>
                    <img
                      style={{ marginRight: '0.75em' }}
                      src="/images/about/privacy.png"
                      alt="Use Jiemba"
                    />
                    <h4>Data privacy regulations</h4>
                    <IconButton
                      onClick={() => setActive(3)}
                      style={{ marginLeft: 'auto' }}>
                      <NavigateNext
                        style={{ fill: '#73BA9B' }}
                        fontSize="default"
                      />
                    </IconButton>
                  </div>
                </Paper>
              </Grid>
              <Grid item style={{ marginTop: '3em' }}>
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  alignItems={matchesMD ? 'center' : undefined}>
                  <Grid item>
                    <a
                      className={classes.link}
                      href="mailto:support@journi.health">
                      Give feedback
                    </a>
                  </Grid>
                  <Grid item style={{ marginTop: '2em' }}>
                    <div className={classes.link}>Recommend to friends</div>
                  </Grid>
                  <Grid item>
                    <div className={classes.iconContainer}>
                      <FacebookShareButton url={url}>
                        <FacebookIcon
                          style={{ fontSize: '40px', color: '#73BA9B' }}
                        />
                      </FacebookShareButton>

                      <WhatsappShareButton
                        style={{ marginLeft: '1em' }}
                        url={url}>
                        <WhatsAppIcon
                          style={{ fontSize: '40px', color: '#73BA9B' }}
                        />
                      </WhatsappShareButton>

                      <LinkedinShareButton
                        style={{ marginLeft: '1em' }}
                        url={url}>
                        <LinkedInIcon
                          style={{ fontSize: '40px', color: '#73BA9B' }}
                        />
                      </LinkedinShareButton>
                      <TwitterShareButton
                        style={{ marginLeft: '1em' }}
                        url={url}>
                        <TwitterIcon
                          style={{ fontSize: '40px', color: '#73BA9B' }}
                        />
                      </TwitterShareButton>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Slide>
  );

  const useJiemba = (
    //Smarth Bajaj - Task #24 - 02/Sept/2020 - Added Back button
    <>
      <div style={{ position: 'relative' }}>
        <Hidden mdDown>
          <IconButton
            style={{ position: 'absolute', top: 100, left: 100 }}
            onClick={() => setActive(0)}>
            <ArrowBackIos style={{ fill: '#73BA9B' }} fontSize="large" />
          </IconButton>
        </Hidden>
      </div>
      <Iframe
        url="https://journi.aihealth.tech/howtojourni/"
        className={classes.frameContainer}
        allowFullScreen={true}
      />
    </>
  );

  const freqQuestions = (
    //Smarth Bajaj - Task #24 - 02/Sept/2020 - Added Back button
    <>
      <div style={{ position: 'relative' }}>
        <Hidden mdDown>
          <IconButton
            style={{ position: 'absolute', top: 100, left: 100 }}
            onClick={() => setActive(0)}>
            <ArrowBackIos style={{ fill: '#73BA9B' }} fontSize="large" />
          </IconButton>
        </Hidden>
      </div>
      <Iframe
        url="https://journi.aihealth.tech/faq"
        className={classes.frameContainer}
        allowFullScreen={true}
      />
    </>
  );

  const privacyQues = (
    //Smarth Bajaj - Task #24 - 02/Sept/2020 - Added Back button
    <>
      <div style={{ position: 'relative' }}>
        <Hidden mdDown>
          <IconButton
            style={{ position: 'absolute', top: 100, left: 100 }}
            onClick={() => setActive(0)}>
            <ArrowBackIos style={{ fill: '#73BA9B' }} fontSize="large" />
          </IconButton>
        </Hidden>
      </div>
      <Iframe
        url="https://journi.aihealth.tech/privacy-policy/"
        className={classes.frameContainer}
        allowFullScreen={true}
      />
    </>
  );

  return (
    <React.Fragment>
      <AppBar className={clsx(classes.root, className)}>
        <Toolbar>
          <div style={{ flexGrow: 1 }}>
            <Hidden lgUp>
              <IconButton color="inherit">
                {active === 0 ? (
                  <Menu onClick={onOpenNavBarMobile} className={classes.icon} />
                ) : (
                  <CloseIcon
                    className={classes.icon}
                    onClick={() => setActive(0)}
                  />
                )}
              </IconButton>
            </Hidden>
          </div>
          <div style={{ flexGrow: 2 }}>
            <Typography variant="h1">{title}</Typography>
          </div>
          <div onClick={handleClickOpen}>
            <Hidden lgUp>
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAABZVBMVEUAAAD/gAD/qgD/gED/mTP/gCv/mRrxjhzylBvyjCbzkiT2jhz2kiT2lSP3kSL1kCL2kiH2kiD2lB/2kyH2kyD2kSD3kyL3kiL2kiD3kyL3kiH3kyD3kSD4kiL2kiH2kiH2kSH3kSL3kiL3kyH3kiH4kiH4kiD4kyL4kiL2kSH3kiH3kyD3kiL3kiH2kiH3kiH3kiD3kiL3kiH3kiH3kiH3kyL3kiH3kiH3kiH3kiH4kiH3kiH3kiH3kiH3kiH3kyP3kyT3lCT3lSb3lSf3lSj3lin3lir3lyv4nDX4njn4pEb4pUf5qE35qlH5qlL5q1P5rFb5rFf5tGb6tGf6tWj6tWn6t2z6u3X6wH76wYH7yJD7yZD80J/80aH80aL82bL82rT83Lj93br93rv93rz937395839587958/96ND+69b+7Nn+7dr+7t3+79/+8OD+8OH+8+f/+/b/+/f//Pn//Pr///8uxljWAAAAPnRSTlMAAgMEBQYKEhMUFRscHR41Njg5VVdYWlt3eHqGh4iTlJWXmJmbq62ur7O0tba70dLU1eHi4+Tq6+zt7vj5+kGlJc4AAAABYktHRHYxY8lBAAABsElEQVQYGYXBiUPSYBwG4HdIKEF5lyIeqGilBYWGc/O1w4vuw6LLyEqziyHV7++PjTEHfIPnQZPQUGLh5vrGxnp2ITGoIciFyVX6rCTjUDk/Z7KFmYqizSWdCmujaBaeZ4DZMHwiywy0FIEnvMwOrp9Dwzw7SsF1mV2MwNGXp+P170ql8vMp2+lR2OZY90VsH6kwg5q4ybqS2D5RwbwIYJKuA7EdUyUJaCt0vRXbvx0qrIYwxIbn4nhMlQEk2HD/j9RYu1QZxyI9hyJiFaiURpaefRGrQLUMdHruVuSIAfIweaYo8pJqBkye2TyR0z0qGdDp87AqJ9tUySNLv6LI123adh9t0SeDRTZ5L/J9j2TBkr/HpYMndKWRYLN3Iqf7LFji+EHXGAbZolgVObKkrkxXP7QcWzz4Jp4y625pQJKtNouWuMqsmwAQN9nmzovDqth+0WHEUJOiyr1nbz6UPr+iYwq23jy7WIvCMcouhuGaZUfTaAhdYQfXeuCJLDHQ1Qh8wikGmO5Bs5E8FW4Po03fjMEWxlQvVGLJHH1yEzEE0QbG0xndNPUb6bF+DX7/AXdlTG8YbnDpAAAAAElFTkSuQmCC"
                alt=""
                style={{ cursor: 'pointer' }}
              />
            </Hidden>
          </div>
        </Toolbar>
        {open && <Emergency open={open} close={handleClose} />}
      </AppBar>
      {active === 0
        ? MainPage
        : active === 1
        ? useJiemba
        : active === 2
        ? freqQuestions
        : active === 3
        ? privacyQues
        : undefined}
    </React.Fragment>
  );
};

export default TopBar;
