import { AppThunk } from 'store';
import { fetchEmergency } from './supportSlice';
import { stopLoading, startLoading } from '../safetySlice';
import {
  callNetworkContactListApi,
  callNetworkContactUpdateApi,
  callNetworkContactCreateApi
} from 'slices/network/action';
import { Network } from 'types/network';

//** ASYNC FUNCS */
export const fetchEmergencyContacts = (): AppThunk => async dispatch => {
  try {
    const networks = await callNetworkContactListApi();

    const emergencyNetworks = networks
      .filter(item => item.CallForSupport === true);
    for (var i = 1; i <= emergencyNetworks.length; i++) {
      emergencyNetworks[i - 1].Order = i;
    }
    sessionStorage.setItem('emergency', JSON.stringify(emergencyNetworks));

    dispatch(
      fetchEmergency({
        emergencyNetworks
      })
    );
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

export const updateEmergencyContacts = (
  emergencyContacts: Network[]
): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());

    emergencyContacts = JSON.parse(JSON.stringify(emergencyContacts));

    for (var i = 0; i < emergencyContacts.length; i++) {
      emergencyContacts[i].Order = i + 1;
    }

    const oldContacts = await callNetworkContactListApi();
    let oldEmergencyContacts = oldContacts.filter(item => item.CallForSupport);
    const newCustomNumbers = emergencyContacts.filter(
      contact => !oldContacts.find(item => item.Id === contact.Id)
    );

    emergencyContacts = emergencyContacts.filter(
      contact => !newCustomNumbers.find(item => item.Id === contact.Id)
    );

    for (const network of oldEmergencyContacts.filter(
      item => !emergencyContacts.find(contact => contact.Id === item.Id)
    )) {
      const updatedNetwork = {
        ...network,
        CallForSupport: false
      };
      await callNetworkContactUpdateApi(updatedNetwork);
    }

    for (const network of emergencyContacts.filter(
      item => !oldEmergencyContacts.find(contact => contact.Id === item.Id)
    )) {
      const updatedNetwork = {
        ...network,
        CallForSupport: true
      };
      emergencyContacts = emergencyContacts.filter((elem) => elem.Id != updatedNetwork.Id)
      await callNetworkContactUpdateApi(updatedNetwork);
    }

    for (const network of newCustomNumbers) {
      await callNetworkContactCreateApi(network);
    }

    for (const contact of emergencyContacts) {
      await callNetworkContactUpdateApi(contact);
    }

    await dispatch(fetchEmergencyContacts());

    dispatch(stopLoading());
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

export const deleteAllEmergencyContacts = (): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());

    const allContacts = await callNetworkContactListApi();

    for (const network of allContacts) {
      const updatedNetwork = {
        ...network,
        CallForSupport: false
      };
      await callNetworkContactUpdateApi(updatedNetwork);
    }

    await dispatch(fetchEmergencyContacts());

    dispatch(stopLoading());
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};
