import React, { Dispatch, SetStateAction, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Grid,
  IconButton,
  Theme,
  useMediaQuery,
  Dialog,
  DialogContent
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Network } from 'types/network';
import {
  ArrowBackIos,
  DeleteOutline,
  AddCircleOutline,
  DragIndicatorOutlined
} from '@material-ui/icons';
import { NetworkList, Loader } from 'common/components';
import { updateEmergencyContacts } from 'slices/safety/support/action';
import { RootState } from 'reducer';

// import AddNumber from './AddNumber'; // Old implementation
// Instead of making it specific to Emergency contacts, made it a common component so that it can be reused in other pages as well
import AddNumber from '../../../AddNumber' // Prafful Jagtap - PreUAT S2 - Task 112

// import NumberForm from './NumberForm'; // Old implementation
// Instead of making it specific to Emergency contacts, made it a common component so that it can be reused in other pages as well
import NumberForm from '../../../NumberForm' // Prafful Jagtap - PreUAT S2 - Task 112

const useStyles = makeStyles(() => ({
  header: {
    padding: '20px 0',
    backgroundColor: '#73BA9B'
  },
  title: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '30px',
    color: '#D5F2E3'
  },
  nameText: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#003E1F'
  },
  addText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#73BA9B'
  },
  number: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '35px',
    color: '#F79221'
  },
  saveButtonText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#D5F2E3',
    cursor: 'pointer'
  },
  editDiv: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '41px',
    height: '41px',
    backgroundColor: '#D5F2E3',
    borderRadius: '25px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer'
  },
  note: {
    padding: '15px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '127.69%',
    color: '#37474F'
  }
}));

type Props = {
  close: () => void;
  setEdit: Dispatch<SetStateAction<boolean>>;
};

const EditEmergency: React.FC<Props> = ({ close, setEdit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loading: boolean = useSelector(
    (state: RootState) => state.safetyRoot.safety.loading
  );

  const [emergencyNetworks, setEmergencyNetworks] = useState<Network[]>(
    JSON.parse(sessionStorage.getItem('emergency')!)
  );

  if (emergencyNetworks.find(elem => elem === null)) {
    setEmergencyNetworks(emergencyNetworks.filter(contact => contact != null))
  }

  /** Theme */
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNetworkCallBack = (networks: Network[]) => {
    setEmergencyNetworks(networks);
    // networks.forEach(item => {
    //   if (!emergencyNetworks.some(network => network.Id === item.Id)) {
    //     setEmergencyNetworks(value => [item, ...value]);
    //   }
    // });
  };

  const handleDeleteButtonClick = (id: string) => {
    var updatedEmergencyNetwork = emergencyNetworks.filter(
      item => item.Id !== id
    );
    updatedEmergencyNetwork = updatedEmergencyNetwork.filter(
      item => item !== undefined
    )
    setEmergencyNetworks(updatedEmergencyNetwork);
    setEdit(true);
  };

  const handleSaveButtonClick = async () => {
    await dispatch(updateEmergencyContacts(emergencyNetworks));
    setEdit(false);
  };

  const [openNetworkList, setOpenNetworkList] = useState(false);

  const networkListDialog = (
    <Dialog
      open={openNetworkList}
      keepMounted
      fullScreen={fullScreen}
      onClose={() => setOpenNetworkList(false)}>
      <DialogContent>
        <NetworkList
          selectedNetworks={emergencyNetworks}
          close={() => setOpenNetworkList(false)}
          callback={networks => handleNetworkCallBack(networks)}
          title="Add to Click to Call"
          connected={false} // To show all networks
        />
      </DialogContent>
    </Dialog>
  );

  const [openAddNumber, setOpenAddNumber] = useState(false);
  const [openAddManually, setOpenAddManually] = useState(false);

  const openNetworkListCloseAddNumber = () => {
    setOpenAddNumber(false);
    setOpenNetworkList(true);
  };

  const openNumberFormCloseAddNumber = () => {
    setOpenAddNumber(false);
    setOpenAddManually(true);
  };

  // This is the dialog that gives the option to either manually add a contact or add from network
  const addNumberDialog = (
    <Dialog
      open={openAddNumber}
      keepMounted
      fullScreen={fullScreen}
      onClose={() => setOpenAddNumber(false)}>
      <DialogContent>
        <AddNumber
          close={() => setOpenAddNumber(false)}
          addManually={openNumberFormCloseAddNumber}
          selectFromNetworks={openNetworkListCloseAddNumber}
        />
      </DialogContent>
    </Dialog>
  );

  const addCustomNumber = (network: Network) => {
    setEmergencyNetworks(value => [network, ...value]);
  };

  // This is the dialog where you add a contact Manually
  const numberFormDialog = (
    <Dialog
      open={openAddManually}
      keepMounted
      fullScreen={fullScreen}
      onClose={() => setOpenAddManually(false)}>
      <DialogContent>
        <NumberForm
          close={() => setOpenAddManually(false)}
          addContact={addCustomNumber}
          support={true}
        />
      </DialogContent>
    </Dialog>
  );

  // Drag and Drop Feature - Touch features for mobile (The function names are pretty self explanatory)
  // const [dragging, setDragging] = useState(0);
  sessionStorage.setItem('drag_num', '0');

  const allowDrop = (ev: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    ev.preventDefault();
  }

  const drag = (ev: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>, network: Network) => {
    // setDragging(network.Order);
    sessionStorage.setItem('drag_num', JSON.stringify(network.Order))
  }

  const drop = async (ev: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>, dropping: number) => {
    ev.preventDefault();
    var elems: Network[] = emergencyNetworks;
    // var elem: Network = elems.splice(dragging - 1, 1)[0]
    var elem: Network = elems.splice(JSON.parse(sessionStorage.getItem('drag_num')!) - 1, 1)[0]
    await elems.splice(dropping - 1, 0, elem)
    elems = await elems.filter(item => item !== undefined)
    setOrder(elems).then(response => {
      setEmergencyNetworks(response)
      // setDragging(0);
      sessionStorage.setItem('drag_num', '0');
    })
  }

  const setOrder = async (contacts: Network[]) => {
    for (var i = 0; i < contacts.length; i++) {
      contacts[i].Order = i + 1;
    }
    return contacts;
  }

  // As the drag and drop for mobile require touch events, here is a different logic to deal with touch events - Issue 30 - Prafful
  var target: HTMLElement;
  var drop_val: number = 0;

  // This function will find the element 
  const touch = (ev: React.TouchEvent<HTMLDivElement>, dropping: number) => {
    var endTarget = document.elementFromPoint(
      ev.touches[0].pageX,
      ev.touches[0].pageY
    );

    target = endTarget?.parentElement!;
    drop_val = dropping;
  }

  const touchEnd = async (ev: React.TouchEvent<HTMLDivElement>) => {
    ev.preventDefault();
    var i: number = 0;
    var elem = target?.parentElement;
    var found = false;
    while (i < 5) {
      if (elem?.className == 'items') {
        found = true;
        break;
      } else {
        elem = elem?.parentElement!;
      }
      i++;
    }

    if (found && elem != null) {
      var dragging: number = 0;
      dragging = emergencyNetworks.find(val => val.Id == elem!.id)?.Order!;
      var elems: Network[] = emergencyNetworks;
      var elem1: Network = elems.splice(JSON.parse(sessionStorage.getItem('drag_num')!) - 1, 1)[0]
      // var elem1: Network = elems.splice(drop_val - 1, 1)[0]
      await elems.splice(dragging - 1, 0, elem1)
      elems = await elems.filter(item => item !== undefined)
      setOrder(elems).then(response => {
        setEmergencyNetworks(response)
        // setDragging(0);
        sessionStorage.setItem('drag_num', '0');
      })
    }
  }

  return (
    <>
      {loading && <Loader />}
      <Grid container justify="center">
        <Grid item xs={12} container className={classes.header}>
          <Grid item xs={2} container justify="center" alignItems="center">
            <IconButton style={{ padding: '0' }} onClick={() => setEdit(false)}>
              <ArrowBackIos style={{ fill: '#D5F2E3', cursor: 'pointer' }} />
            </IconButton>
          </Grid>
          <Grid item xs={8}>
            <span className={classes.title}>Edit Click to Call</span>
          </Grid>
          <Grid item xs={2} container alignItems="center">
            <span
              className={classes.saveButtonText}
              onClick={handleSaveButtonClick}>
              Save
            </span>
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <div className={classes.note}>
            You can arrange, add, and delete contacts for your Click to Call
            list. To make the contacts more visible at emergency, we recommend
            to not have more than 5 contacts here.
          </div>
        </Grid>
        <div style={{ padding: '20px 0' }} >
          <Grid item xs={12} container justify="center" spacing={2}>
            {emergencyNetworks.map((network, index) => {
              return (
                <div className="items" draggable="true" onTouchStart={(event) => drag(event, network)} onDragStart={(event) => drag(event, network)} key={index} id={network.Id}
                  onDrop={(event) => drop(event, network.Order)} onDragOver={(event) => allowDrop(event)}
                  onTouchMove={(event) => touch(event, network.Order)}
                  onTouchEnd={(event) => touchEnd(event)}
                  style={{ width: '80%' }}>
                  <Grid item xs={12} container alignItems="flex-end" >
                    <Grid item xs={2} container justify="flex-end">
                      <IconButton>
                        <DragIndicatorOutlined
                          fontSize="large"
                          style={{ fill: '#73BA9B', cursor: 'move', padding: 5 }}
                        />
                      </IconButton>
                    </Grid>
                    <Grid item xs={8} container direction="column">
                      {/* Caroline Bezzina 25/09/2020 Task J28 - No name for suggested contacts on Emergency and Network */}
                      <span className={classes.nameText}>{network.Name ? network.Name : network.ContactName}</span>
                      <span>
                        <a
                          className={classes.number}
                          //Hammad Tahir - contactPhoneFix - 250921
                          href={`tel:${network.Phone ? network.Phone : network.ContactPhone}`}>
                          {network.Phone ? network.Phone : network.ContactPhone}
                        </a>
                      </span>
                    </Grid>
                    <Grid item xs={2} container justify="flex-end">
                      {network.Phone !== '000' && (
                        <IconButton
                          onClick={() => handleDeleteButtonClick(network.Id)}>
                          <DeleteOutline
                            fontSize="large"
                            style={{ fill: '#73BA9B', cursor: 'pointer' }}
                          />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </div>
              );
            })}
            <Grid item xs={10} container alignItems="center" justify="center">
              <IconButton onClick={() => setOpenAddNumber(true)}>
                <AddCircleOutline style={{ fill: '#73BA9B' }} />
              </IconButton>
              <span className={classes.addText}>Add</span>
            </Grid>
          </Grid>
        </div>
      </Grid>
      {openNetworkList && networkListDialog}
      {openAddNumber && addNumberDialog}
      {openAddManually && numberFormDialog}
    </>
  );
};

export default EditEmergency;
