import { AppThunk } from 'store';
import axios from 'common/utils/axios';
import authentication from '@kdpw/msal-b2c-react';
import {
  read,
  add,
  remove,
  startLoading,
  stopLoading,
  fetchSharedList,
  startCheckingState,//Jorge Avendano T43.4 020920 loader implementation
  stopCheckingState,
  hideError,
  showError,
  startDisconnectLoader, // Prafful Jagtap - UAT S1 - Task 218 - To show loader when user is disconnected
  stopDisconnectLoader, // Prafful Jagtap - UAT S1 - Task 218 - To show loader when user is disconnected
} from './networkSlice';
import {
  fetchStepsSharedNetworks,
  fetchGoalsSharedNetworks,
  callGoalShareListApi, //Hammad Tahir - disconnectContactFix - 280921
  callGoalStepShareListApi, //Hammad Tahir - disconnectContactFix - 280921 - Start
  calllGoalStepShareCreateApi, //Hammad Tahir - changeAccessFix - 290921
  callGoalShareCreateApi //Hammad Tahir - changeAccessFix - 290921
} from 'slices/goal/share/action';
import { fetchJournalsData, callJournalListApi } from 'slices/journey/action';
import {
  fetchStoryData,
  callMyStoryReadApi,
  callMyStoryShareListApi
} from 'slices/story/action';
import {
  fetchSafetySharedList,
  callSafetyPlanShareListApi,
  fetchSafetyPlanServices //Hammad Tahir - Fetch and load safety plan data to avoid disabling safety plan access button
} from 'slices/safety/action';
import { Network, ShareNetworkApi, Invitation } from 'types/network';
import moment from 'moment';
import {
  fetchJournalsSharedNetworks,
  callJournalShareListApi,
  callJournalShareDeleteApi,
  callJournalShareCreateApi
} from 'slices/journey/share/action';
import {
  callInvitationListApi,
  callInvitationReadApi,
  callInvitationCreateApi,
  fetchPendingContactFromInvitation,
  callInvitationDelete //Hammad Tahir - delete sent invitation for pendinng network contact
} from './invitation/action';
import { callGoalListApi, fetchGoals } from 'slices/goal/action';
import {
  callGoalShareDeleteApi,
  startSharingAllGoals,
  stopAllGoalsSharing
} from './access/goals/action';
import {
  callSafetyPlanShareDeleteApi,
  shareSafetyPlan,
  stopSharingSafetyPlan
} from './access/safetyplan/action';
import {
  callMyStoryShareDeleteApi,
  startSharingStory,
  stopSharingStory
} from './access/mystory/action';
import {
  startSharingAllJournals,
  stopSharingAllJournals
} from './access/journals/action';
import {
  startSharingContact,
  stopSharingContact,
  callNetworkShareDeleteApi
} from './access/contacts/action';
import { fetchConnectRequests } from './invitation/invitationSlice';// Jorge Avendano T29 110820 fetch invitations to the reducer
import { Step } from 'types/goal'; //Hammad Tahir - disconnectContactFix - 280921
import { callGoalStepListApi } from 'slices/goal/step/action'; //Hammad Tahir - disconnectContactFix - 280921
import { callGoalStepShareDeleteApi } from './access/steps/action'; //Hammad Tahir - disconnectContactFix - 280921
import { fetchEmergency } from 'slices/safety/support/supportSlice'; //Hammad Tahir T169 071021  - "Add to Emergency/Safety Plan" switch not reflecting straight away

export const fetchNetworks = (): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());
    const networks = await callNetworkContactListApi();
    const invitationsSentToOthers = await callInvitationListApi();
    const connectRequestInvitations = await callInvitationReadApi();

    // Check Pending
    const updatedNetworks = networks.map(network => {
      const unAcceptedInvitationsLen = invitationsSentToOthers.filter(
        item => item.EmailAddress === network.Email && !item.AcceptedOn
      ).length;
      const acceptedSentInvitationsLen = invitationsSentToOthers.filter(
        item => item.EmailAddress === network.Email && item.AcceptedOn
      ).length;

      const updatedNetwork: Network = {
        ...network,
        Status:
          network.Email != null &&
            network.Phone !== '' &&
            unAcceptedInvitationsLen > 0 &&
            acceptedSentInvitationsLen === 0
            ? 'Pending'
            : ''
      };

      return updatedNetwork;
    });

    //Hammad Tahir - fetchNetworkFix - 280921 - Start
    // Jorge Avendano T29 110820 fetch invitations to the reducer begin
    const connectRequests = connectRequestInvitations.sort(
      (a, b) =>
        new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime()
    );
    dispatch(fetchConnectRequests({ connectRequests }));
    // Jorge Avendano T29 110820 fetch invitations to the reducer end
    //Hammad Tahir - fetchNetworkFix - End

    const updatedNetworks2 = updatedNetworks.map(network => {
      const unAcceptedReceivedInvitationsLen = connectRequestInvitations.filter(
        item =>
          item.InviterUserEmailAddress === network.Email && !item.AcceptedOn
      ).length;
      const acceptedReceivedInvitationsLen = connectRequestInvitations.filter(
        item =>
          item.InviterUserEmailAddress === network.Email && item.AcceptedOn
      ).length;

      const updatedNetwork: Network = {
        ...network,
        Status:
          network.Status === '' &&
            network.Email != null &&
            network.Phone !== '' &&
            unAcceptedReceivedInvitationsLen > 0 &&
            acceptedReceivedInvitationsLen === 0
            ? 'Pending'
            : network.Status
      };

      return updatedNetwork;
    });

    //Jorge Avendano T43.4 020920 Split loading of information to fetchSharedInformation() begin
    // Check isConnected
    //let allSharedNetworks: ShareNetworkApi[] = [];

    // const goals = await callGoalListApi();
    // for (const goal of goals) {
    //   const networks = await callGoalShareListApi(goal.Id);
    //   allSharedNetworks = allSharedNetworks.concat(networks);
    // }

    // const journals = await callJournalListApi();
    // for (const journal of journals) {
    //   const networks = await callJournalShareListApi(journal.Id);
    //   allSharedNetworks = allSharedNetworks.concat(networks);
    // }

    // const safetyPlanShareList = await callSafetyPlanShareListApi();
    // allSharedNetworks = allSharedNetworks.concat(safetyPlanShareList);

    // const story = await callMyStoryReadApi();
    // const myStoryShareList = await callMyStoryShareListApi(story.MyStoryId);
    // allSharedNetworks = allSharedNetworks.concat(myStoryShareList);

    // const updatedNetworks3 = updatedNetworks2.map(network => {
    //   if (network.Email != null && network.Status === '') {
    //     const updatedNetwork: Network = {
    //       ...network,
    //       Status: allSharedNetworks.find(
    //         item => item.SharedWithNetworkContactId === network.Id
    //       )
    //         ? 'Connected'
    //         : 'Disconnected'
    //     };
    //     return updatedNetwork;
    //   }

    //   return network;
    // });
    //Jorge Avendano T43.4 020920 end

    dispatch(
      read({
        networks: updatedNetworks2
      })
    );

    dispatch(stopLoading());
    dispatch(fetchSharedInformation(updatedNetworks2));//Jorge Avendano T213 Fix loading discrepancies
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

//Jorge Avendano T43.4 020920 Split loading of the shared network information
export const fetchSharedInformation = (networkList: Network[]): AppThunk => async dispatch => {
  try {
    dispatch(startCheckingState());

    if (networkList.length > 0) {


      let allSharedNetworks: ShareNetworkApi[] = [];

      const goals = await callGoalListApi();
      for (const goal of goals) {
        const networks = await callGoalShareListApi(goal.Id);
        allSharedNetworks = allSharedNetworks.concat(networks);
      }

      const journals = await callJournalListApi();
      for (const journal of journals) {
        const networks = await callJournalShareListApi(journal.Id);
        allSharedNetworks = allSharedNetworks.concat(networks);
      }

      const safetyPlanShareList = await callSafetyPlanShareListApi();
      allSharedNetworks = allSharedNetworks.concat(safetyPlanShareList);

      const story = await callMyStoryReadApi();
      const myStoryShareList = await callMyStoryShareListApi(story.MyStoryId);
      allSharedNetworks = allSharedNetworks.concat(myStoryShareList);

      //Hammad Tahir - Get list of shared networks
      const networkShareList = await callNetworkShareListApi();
      allSharedNetworks = allSharedNetworks.concat(networkShareList);

      const updatedNetworks3 = networkList.map(network => {
        if (network.Email != null && network.Status === '') {
          const updatedNetwork: Network = {
            ...network,
            Status: allSharedNetworks.find(
              item => item.SharedWithNetworkContactId === network.Id
            )
              ? 'Connected'
              : 'Disconnected'
          };
          return updatedNetwork;
        } 
        //Hammad Tahir 061021 - add/edit network contacts - Set network contacts without email as 'NoConnection'
        else if (network.Email === null && network.Order > 4 && network.Status === ''){
          const updatedNetwork: Network = {
            ...network,
            Status: 'NoConnection'
          };
          return updatedNetwork;
        }
        //Hammad Tahir 061021 - add/edit network contacts - Set emergency services contacts as 'Default'
        else if (network.Email === null && network.Order <= 4 && network.Status === ''){
          const updatedNetwork: Network = {
            ...network,
            Status: 'Default'
          };
          return updatedNetwork;
        }

        return network;
      });

      dispatch(
        read({
          networks: updatedNetworks3
        })
      );
    }
    dispatch(stopCheckingState());
  }
  catch (err) {
    dispatch(stopCheckingState());
  }
}

export const fetchOtherSharedList = (): AppThunk => async (dispatch,getState) => {
  try {
    dispatch(startLoading());

    if(getState().goalRoot.goal.goals.length === 0)
    {
      await dispatch(fetchGoals());
    }
    await dispatch(fetchStepsSharedNetworks());
    await dispatch(fetchGoalsSharedNetworks());

    if(getState().journeyRoot.journey.journals.length === 0)
    {
      await dispatch(fetchJournalsData());
    }
    await dispatch(fetchJournalsSharedNetworks());
    await dispatch(fetchStoryData());
    await dispatch(fetchSafetySharedList());

    //Hammad Tahir - Fetch and load safety plan data to avoid disabling safety plan access button
    if(getState().safetyRoot.support.emergencyNetworks.length === 0)
    {
      await dispatch(fetchSafetyPlanServices());
    }
    
    await dispatch(fetchContactSharedList());

    dispatch(stopLoading());
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

export const fetchContactSharedList = (): AppThunk => async dispatch => {
  try {
    const sharedData = await callNetworkShareListApi();
    dispatch(fetchSharedList({ networks: sharedData }));
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

export const addNetwork = (
  history: any,
  network: Network,
  invitation?: Invitation
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(hideError()); // Prafful Jagtap - Issue J14 - Display error message on fail API 
    dispatch(startLoading());

    let updatedNetwork: Network;

    if (invitation) {
      await callInvitationCreateApi(invitation).catch(err => {
        throw err; // Prafful Jagtap - Issue J14 - Display error message on fail API 
      })
      await dispatch(fetchPendingContactFromInvitation());

      const response = await callNetworkContactCreateApi(network);
      const responseArray = response.split(' ');
      updatedNetwork = {
        ...network,
        Id: responseArray[5]
      };
      dispatch(add({ network: updatedNetwork }));

      //Hammad Tahir - estaiblish shares after succesfull connection - 280921 - Start
      // // sharing journals
      // if (invitation.ShareAllJournals) {
      //   await dispatch(startSharingAllJournals(updatedNetwork));
      // } else {
      //   await dispatch(stopSharingAllJournals(updatedNetwork));
      // }

      // // sharing goals
      // if (invitation.ShareAllGoals) {
      //   await dispatch(startSharingAllGoals(updatedNetwork));
      // } else {
      //   await dispatch(stopAllGoalsSharing(updatedNetwork));
      // }

      // // sharing my story
      // const myStoryId = getState().story.story.MyStoryId;
      // if (myStoryId) {
      //   if (invitation.ShareMyStory) {
      //     dispatch(startSharingStory(myStoryId, updatedNetwork));
      //   } else {
      //     dispatch(stopSharingStory(myStoryId, updatedNetwork));
      //   }
      // }

      // // sharing safety plan
      // if (invitation.ShareSafetyPlan) {
      //   await dispatch(shareSafetyPlan(updatedNetwork));
      // } else {
      //   await dispatch(stopSharingSafetyPlan(updatedNetwork));
      // }

      // // sharing contacts
      // if (invitation.ShareNetworkContacts) {
      //   await dispatch(startSharingContact(updatedNetwork));
      // } else {
      //   await dispatch(stopSharingContact(updatedNetwork));
      // }
      //Hammad Tahir - estaiblish shares after succesfull connection - End
    } else {
      const response = await callNetworkContactCreateApi(network);
      const responseArray = response.split(' ');
      updatedNetwork = {
        ...network,
        Id: responseArray[5]
      };
      dispatch(add({ network: updatedNetwork }));
    }

    dispatch(stopLoading());

    if (updatedNetwork.Type === 'Person') {
      history.push('/networks/people');
    } else {
      history.push('/networks/services');
    }
  } catch (err) {
    dispatch(showError()) // Prafful Jagtap - Issue J14 - Display error message on fail API 
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

export const deleteContact = (network: Network): AppThunk => async dispatch => {
  try {
    dispatch(startDisconnectLoader()); // Prafful Jagtap - UAT S1 - Task 218 - To show loader when user is deleted
    await callNetworkContactDeleteApi(network);
    dispatch(remove({ network }));
    //Hammad Tahir - Start - delete sent invitation for pendinng network contact
    if(network.Status === 'Pending'){
      const sentInvitations = await callInvitationListApi();
      const invitation = sentInvitations
          .find(
            item => item.EmailAddress === network.ContactEmail
          );
        invitation &&
          (await callInvitationDelete(invitation.InvitationId))
    } ////Hammad Tahir - End
    dispatch(stopDisconnectLoader()); // Prafful Jagtap - UAT S1 - Task 218 - To show loader when user is deleted
  } catch (err) {
    dispatch(stopDisconnectLoader()); // Prafful Jagtap - UAT S1 - Task 218 - To show loader when user is deleted
    // dispatch(failed(err.toString()));
  }
};

export const disconnectContact = (
  network: Network
): AppThunk => async dispatch => {
  try {
    dispatch(startDisconnectLoader()); // Prafful Jagtap - UAT S1 - Task 218 - To show loader when user is disconnected

    // delete goal shares
    const goals = await callGoalListApi();
    for (const goal of goals) {
      const networks = await callGoalShareListApi(goal.Id);
      await Promise.all(
        networks
          .filter(item => item.SharedWithNetworkContactId === network.Id)
          .map(async network => {
            await callGoalShareDeleteApi(
              goal.Id,
              network.SharedWithNetworkContactId
            );
          })
      );
    }

    //Hammad Tahir - disconnectContactFix - 280921 - Start
    // delete step shares
    let totalSteps: Step[] = [];
    for (const goal of goals) {
      const steps = await callGoalStepListApi(
        goal.Id,
        sessionStorage.getItem('UserId')!
      );
      totalSteps = totalSteps.concat(steps);
    }
    for (const step of totalSteps) {
      const networks = await callGoalStepShareListApi(step.Id)
      await Promise.all(
        networks
          .filter(item => item.SharedWithNetworkContactId === network.Id)
          .map(async network => {
            await callGoalStepShareDeleteApi(
              step.Id,
              network.SharedWithNetworkContactId
            );
          })
      );
    }
    //Hammad Tahir - disconnectContactFix - End

    // delete journal shares
    const journals = await callJournalListApi();
    for (const journal of journals) {
      const networks = await callJournalShareListApi(journal.Id);
      await Promise.all(
        networks
          .filter(item => item.SharedWithNetworkContactId === network.Id)
          .map(async network => {
            await callJournalShareDeleteApi(
              journal.Id,
              network.SharedWithNetworkContactId
            );
          })
      );
    }

    // delete safety plan shares
    const safetyPlanShareList = await callSafetyPlanShareListApi();
    await Promise.all(
      safetyPlanShareList
        .filter(item => item.SharedWithNetworkContactId === network.Id)
        .map(async network => {
          await callSafetyPlanShareDeleteApi(
            network.SharedWithNetworkContactId
          );
        })
    );

    // delete my story share
    const story = await callMyStoryReadApi();
    const myStoryShareList = await callMyStoryShareListApi(story.MyStoryId);
    await Promise.all(
      myStoryShareList
        .filter(item => item.SharedWithNetworkContactId === network.Id)
        .map(async network => {
          await callMyStoryShareDeleteApi(
            story.MyStoryId,
            network.SharedWithNetworkContactId
          );
        })
    );

    // delete my contacts
    const sharedContactsList = await callNetworkShareListApi();
    await Promise.all(
      sharedContactsList
      .filter(item => item.SharedWithNetworkContactId === network.Id) //Hammad Tahir - disconnectContactFix - 280921 - Only delete network share for the selected contact
      .map(async network => {
        await callNetworkShareDeleteApi(network.SharedWithNetworkContactId);
      })
    );

    await dispatch(fetchNetworks());

    dispatch(stopDisconnectLoader()); // Prafful Jagtap - UAT S1 - Task 218 - To show loader when user is disconnected
  } catch (err) {
    dispatch(stopDisconnectLoader());
    // dispatch(failed(err.toString()));
  }
};

export const updateNetwork = (
  history: any,
  network: Network,
  access: {
    goal: boolean;
    journey: boolean;
    story: boolean;
    safety: boolean;
    network: boolean;
  },
  allGoalsAndJournals: {
    goals: boolean;
    journals: boolean;
  }
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    await callNetworkContactUpdateApi(network);

    // Issue J18 - Prafful Jagtap - On click of save button, all goals and journals were given access to
    // sharing journals
    if (allGoalsAndJournals.journals && access.journey) {  //Hammad Tahir - changeAccessFix - 290921
      await dispatch(startSharingAllJournals(network));
    } else if (!allGoalsAndJournals.journals && access.journey) {  //Hammad Tahir - changeAccessFix - 290921 - Start - if specific journals update journal shares accordingly
      const selectedJournals = getState().journeyRoot.journey.journals.filter(journal =>
        getState().journeyRoot.journeyShare.share.find(item => 
          item.SharedWithNetworkContactId === network.Id &&
          item.JournalId === journal.Id))

      const unSelectedJournals = getState().journeyRoot.journey.journals.filter(journal =>
        !getState().journeyRoot.journeyShare.share.find(item => 
          item.SharedWithNetworkContactId === network.Id &&
          item.JournalId === journal.Id))

      selectedJournals.map(async item =>
        await callJournalShareCreateApi(item.Id,network))

      unSelectedJournals.map(async item =>
        await callJournalShareDeleteApi(item.Id,network.Id))  //Hammad Tahir - changeAccessFix - End
    } else if (!access.journey) {
      await dispatch(stopSharingAllJournals(network));
    }

    // sharing goals
    if (allGoalsAndJournals.goals && access.goal) {  //Hammad Tahir - changeAccessFix - 290921
      await dispatch(startSharingAllGoals(network));
      const allSteps = getState().goalRoot.goalStep.steps.filter(step => step.VisibleTo !== 'OnlyMe')  //Hammad Tahir - changeAccessFix - 290921 - start sharing all public steps
      allSteps.map(async item =>
        await calllGoalStepShareCreateApi(item.Id,network))
    } else if (!allGoalsAndJournals.goals && access.goal) {  //Hammad Tahir - changeAccessFix - 290921 - Start - if specific goals and steps, update shares accordingly
      const selectedGoals = getState().goalRoot.goal.goals.filter(goal =>
        getState().goalRoot.goalShare.goalsShare.find(item => 
          item.SharedWithNetworkContactId === network.Id &&
          item.GoalId === goal.Id))

      const unSelectedGoals = getState().goalRoot.goal.goals.filter(goal =>
        !getState().goalRoot.goalShare.goalsShare.find(item => 
          item.SharedWithNetworkContactId === network.Id &&
          item.GoalId === goal.Id))
      
      const selectedSteps = getState().goalRoot.goalStep.steps.filter(step =>
        getState().goalRoot.goalShare.stepsShare.find(item => 
          item.SharedWithNetworkContactId === network.Id &&
          item.StepId === step.Id))
      const unSelectedSteps = getState().goalRoot.goalStep.steps.filter(step =>
        !getState().goalRoot.goalShare.stepsShare.find(item => 
          item.SharedWithNetworkContactId === network.Id &&
          item.StepId === step.Id))
      
      selectedGoals.map(async item => 
        await callGoalShareCreateApi(item.Id,network))
      
      unSelectedGoals.map(async item =>
        await callGoalShareDeleteApi(item.Id,network.Id))
      
      selectedSteps.map(async item =>
        await calllGoalStepShareCreateApi(item.Id,network))
      
      unSelectedSteps.map(async item =>
        await callGoalStepShareDeleteApi(item.Id, network.Id))  //Hammad Tahir - changeAccessFix - End
    } else if (!access.goal) {
      await dispatch(stopAllGoalsSharing(network));
      const allSteps = getState().goalRoot.goalStep.steps  //Hammad Tahir - changeAccessFix - 290921 - Stop all step shares
      allSteps.map(async item =>
        await callGoalStepShareDeleteApi(item.Id,network.Id))
    }

    // sharing my story
    const myStoryId = getState().story.story.MyStoryId;
    if (myStoryId) {
      if (access.story) {
        dispatch(startSharingStory(myStoryId, network));
      } else {
        dispatch(stopSharingStory(myStoryId, network));
      }
    }

    // sharing safety plan
    if (access.safety) {
      await dispatch(shareSafetyPlan(network));
    } else {
      await dispatch(stopSharingSafetyPlan(network));
    }

    // sharing contacts
    if (access.network) {
      await dispatch(startSharingContact(network));
    } else {
      await dispatch(stopSharingContact(network));
    }

    //Hammad Tahir T169 071021  - "Add to Emergency/Safety Plan" switch not reflecting straight away
    //Add remove from emergency network as per selected
    const emergencyNetworks: Network[] = getState().safetyRoot.support.emergencyNetworks;
    
    if(network.CallForSupport){
      dispatch(
        fetchEmergency({
          emergencyNetworks: [...emergencyNetworks,network]
        })
      );
      sessionStorage.setItem('emergency', JSON.stringify([...emergencyNetworks, network]));
    }else{
      dispatch(
        fetchEmergency({
          emergencyNetworks: emergencyNetworks.filter(item => item.Id !== network.Id)
        })
      );
      sessionStorage.setItem('emergency', JSON.stringify(emergencyNetworks.filter(item => item.Id !== network.Id)));
    }

    dispatch(stopLoading());

    if (
      sessionStorage.getItem('inviterAccountType') !== null &&
      sessionStorage.getItem('inviterAccountType') !== ''
    ) {
      history.push(
        `/networks/${sessionStorage.getItem('inviterAccountType')! === 'RelatedPerson'
          ? 'people'
          : 'services'
        }`
      );
    } else {
      // history.goBack();
      history.push('/networks/people'); //Hammad Tahir 061021 - go back to networks page after editing contact
    }
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

// Prafful Jagtap - PreUAT S2 - Task 112
export const networkContactCreateApi = (network: Network): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());
    const response = await callNetworkContactCreateApi(network);
    
    const responseArray = response.split(' ');
    network = {
      ...network,
      Id: responseArray[5]
    };
    dispatch(add({ network: network }));
    
    dispatch(stopLoading());
    return network;
  } catch (err) {
    dispatch(stopLoading());
  }
}

export const callNetworkContactCreateApi = (network: Network) => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  const requestBody = {
    ...network,
    Image:
      network.Image === '/images/avatars/carerDefaultAvatar.svg' ||
        network.Image === '/images/avatars/providerDefaultAvatar.png'
        ? ''
        : network.Image.replace('data:image/png;base64,', '')
  };

  return axios.post('/NetworkContact/Create', requestBody).then(response => {
    return response.data;
  });
};

const callNetworkContactDeleteApi = (network: Network) => {
  return axios.delete(`/NetworkContact/Delete/${network.Id}`);
};

export const callNetworkContactUpdateApi = (network: Network) => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  const requestContext = {
    ...network,
    Image:
      network.Image === '/images/avatars/carerDefaultAvatar.svg' ||
        network.Image === '/images/avatars/providerDefaultAvatar.png'
        ? ''
        : network.Image.replace('data:image/png;base64,', '')
  };

  return axios.post(`/NetworkContact/Update/${network.Id}`, requestContext);
};

export const callRecoveryPlanShareCreateApi = (
  networkId: string,
  networkName: string
) => {
  const requestContent = {
    SharedWithNetworkContactId: networkId,
    SharedWithNetworkName: networkName,
    SharedOnDate: moment().format('YYYY-MMM-DD')
  };
  return axios.post('/RecoveryPlanShare/Create', requestContent);
};

export const callNetworkContactListApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  return axios.get('/NetworkContact/List').then(response => {
    const networks: Network[] = JSON.parse(JSON.stringify(response.data));
    const updatedNetworks: Network[] = networks.map(item => {
      return {
        ...item,
        Type: item.Type ? item.Type : 'Person',
        Name: item.Name ? item.Name : '',
        Phone: item.Phone ? item.Phone : '',
        ImageType: item.ImageType ? item.ImageType : '',
        ImageUrl: item.ImageUrl ? item.ImageUrl : '',
        Image: item.Image
          ? 'data:image/png;base64,' + item.Image
          : item.Type === 'Person'
            ? '/images/avatars/carerDefaultAvatar.svg'
            : '/images/avatars/providerDefaultAvatar.png',
        Access: item.Access === '' ? 'No Access' : item.Access?.slice(0,-2), //Hammad Tahir T341 031021 - Missing fields on PDF
        Relationship: item.Relationship ? item.Relationship : '-', //Hammad Tahir T341 031021 - Missing fields on PDF
        Organization: item.Organization ? item.Organization : '-' //Hammad Tahir T341 031021 - Missing fields on PDF
      };
    });
    return updatedNetworks;
  });
};

const callNetworkShareListApi = () => {
  return axios.get(`/NetworkShare/List`).then(response => {
    const networks: ShareNetworkApi[] = JSON.parse(
      JSON.stringify(response.data)
    );
    return networks;
  });
};
