import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationItem, NotificationRootType } from 'types/notification';

const initialState: NotificationRootType = {
  notifications: [],
  //count: '',// Jorge Avendano T29 100820 notifications indicator
  unReadNotifications: [],//Jorge Avendano T8 260820 Browser push notifications
  loading: false
};

const notificationsSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    readNotifications(
      state,
      action: PayloadAction<{ notifications: NotificationItem[] }>
    ) {
      const { notifications } = action.payload;
      state.notifications = notifications;
    },
    // countNotifications(// Jorge Avendano T29 100820 notifications indicator
    //   state,
      //action: PayloadAction<{ count: string }>
    // ) {
    //   const { count } = action.payload;
    //   state.count = count;
    // },
    getUnReadNotifications(//Jorge Avendano T8 260820 Browser push notifications
      state,
    action: PayloadAction<{ unReadNotifications: NotificationItem[] }>
    ) {
      const { unReadNotifications } = action.payload;
      state.unReadNotifications = unReadNotifications;
    },
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    }
  }
});

export const {
  readNotifications,
  //countNotifications,// Jorge Avendano T29 100820 notifications indicator
  getUnReadNotifications,//Jorge Avendano T8 260820 Browser push notifications
  startLoading,
  stopLoading
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
