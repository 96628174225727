import React, { useState, useEffect, ChangeEvent } from 'react';

import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { Grid, TextField } from '@material-ui/core';
import { Button } from 'common/components';
import { Network } from 'types/network';
import uuid from 'uuid';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 10px',
    marginBottom: '10px'
  },
  headerText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '24px',
    color: '#73BA9B'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px',
    maxWidth: '390px'
  },
  buttonContainer: {
    display: 'flex',
    width: '100%'
  },
  buttonContent: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px'
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#003E1F',
    cursor: 'pointer'
  },
  textField: {
    background: '#FFFFFF',
    borderRadius: '5px',
    borderStyle: 'none',
    marginBottom: 20
  }
}));

const schema = {
  Name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 80
    }
  },
  Phone: {
    presence: { allowEmpty: false, message: 'is required' },
    format: {
      pattern: `^\\+?\\d+`
    },
    length: {
      minimum: 3,
      maximum: 12
    }
  }
};

type FormStateType = {
  isValid: boolean;
  values: {
    Name?: string;
    Phone?: string;
  };
  touched: {
    Name?: boolean;
    Phone?: boolean;
  };
  errors: {
    Name?: string[];
    Phone?: string[];
  };
};

type Props = {
  close: () => void;
  addContact: (network: Network) => void;
  support: boolean;
};

const NumberForm: React.FC<Props> = ({ close, addContact, support }) => {
  const classes = useStyles();

  // Caroline Bezzina 23/09/2020 Task J21 - The suggested contact made by SP has no name
  const [contact, setContact] = useState<Network>({
    Id: uuid(),
    UserId: sessionStorage.getItem('UserId')!,
    ContactId: '',
    Name: '',
    ContactName: '',
    Email: '',
    Phone: '',
    CallForSupport: support,
    Order: 0,
    Address: '',
    Type: 'Person',
    Relationship: '',
    Image: '/images/avatars/carerDefaultAvatar.svg', //Hammad Tahir T370 021021 - Add responsible person to "People" by default instead of "Services" and update default image accordingly
    ImageType: '',
    ImageUrl: '',
    ContactAddress: '',
    ContactEmail: '',
    ContactPhone: ''
  });

  const [formState, setFormState] = useState<FormStateType>({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));

    setContact(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  const hasError = (field: string): boolean =>
    field in formState.touched && field in formState.errors ? true : false;

  const handleSubmitButtonClick = () => {
    try {
      setFormState(formState => ({
        ...formState,
        values: {
          Name: contact.Name,
          Phone: contact.Phone
        },
        touched: {
          Name: true,
          Phone: true
        }
      }));

      addContact(contact);
      close();
    } catch (error) { }
  };

  const handleClose = () => {
    setFormState({
      isValid: false,
      values: {},
      touched: {},
      errors: {}
    });
    close();
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <TextField
          error={hasError('Name')}
          fullWidth
          variant="outlined"
          label="Name"
          name="Name"
          autoComplete="off"
          value={contact.Name}
          className={classes.textField}
          onChange={handleChange}
          inputProps={{ maxLength: 80 }}
        />
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12}>
        <TextField
          error={hasError('Phone')}
          fullWidth
          variant="outlined"
          label="Phone number"
          name="Phone"
          autoComplete="off"
          value={contact.Phone}
          className={classes.textField}
          onChange={handleChange}
          inputProps={{ maxLength: 12 }}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.buttonContainer}>
          <div className={classes.buttonContent}>
            <span className={classes.buttonText} onClick={handleClose}>
              Cancel
            </span>
          </div>
          <div className={classes.buttonContent}>
            <Button type="extra" click={handleSubmitButtonClick}>
              Save
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default NumberForm;
