import { AppThunk } from 'store';
import axios from 'common/utils/axios';
import authentication from '@kdpw/msal-b2c-react';
import { Profile } from 'types/profile';
import { start, end } from './authSlice';
import { acceptConnectRequest,
         callInvitationDelete, //Hammad Tahir - invitationFix - 050821 - Addition
         fetchConnectionRequests //Hammad Tahir - invitationFix - 050821 - Addition
        } from 'slices/network/invitation/action';
import { Network } from 'types/network';
import uuid from 'uuid';
import { callNetworkContactCreateApi } from 'slices/network/action';

//** ASYNC FUNCS */
export const startSession = (history: any): AppThunk => async dispatch => {
  try {
    await callProfileCheckApi()
      .then(async response => {
        const profileData = await callProfileReadApi();
        dispatch(
          start({
            avatar: '/images/avatars/avatar_0.png',
            gender: profileData.Gender ? profileData.Gender : ''
          })
        );

        dispatch(redirectAction(history));
      })
      .catch(async error => {
        await callProfileSetupApi();

        const profileData = await callProfileReadApi();
        dispatch(
          start({
            avatar: '/images/avatars/avatar_0.png',
            gender: profileData.Gender ? profileData.Gender : ''
          })
        );

        // Caroline Bezzina 23/09/2020 Task J21 - The suggested contact made by SP has no name
        const defaultEmergencyNumbers: Network[] = [
          {
            Id: uuid(),
            UserId: profileData.UserId,
            ContactId: uuid(),
            Name: 'Emergency services',
            ContactName: '',
            Email: '',
            Phone: '000',
            CallForSupport: true,
            Order: 0,
            Address: '',
            Type: 'Organisation',
            Relationship: '',
            Image: '/images/avatars/providerDefaultAvatar.png',
            ImageType: '',
            ImageUrl: '',
            ContactAddress: '',
            ContactEmail: '',
            ContactPhone: ''
          },
          {
            Id: uuid(),
            UserId: profileData.UserId,
            ContactId: uuid(),
            Name: 'AccessLine',
            ContactName: '',
            Email: '',
            Phone: '1800800944',
            CallForSupport: true,
            Order: 1,
            Address: '',
            Type: 'Organisation',
            Relationship: '',
            Image: '/images/avatars/providerDefaultAvatar.png',
            ImageType: '',
            ImageUrl: '',
            ContactAddress: '',
            ContactEmail: '',
            ContactPhone: ''
          },
          {
            Id: uuid(),
            UserId: profileData.UserId,
            ContactId: uuid(),
            Name: 'LifeLine',
            ContactName: '',
            Email: '',
            Phone: '131114',
            CallForSupport: true,
            Order: 2,
            Address: '',
            Type: 'Organisation',
            Relationship: '',
            Image: '/images/avatars/providerDefaultAvatar.png',
            ImageType: '',
            ImageUrl: '',
            ContactAddress: '',
            ContactEmail: '',
            ContactPhone: ''
          },
          {
            Id: uuid(),
            UserId: profileData.UserId,
            ContactId: uuid(),
            Name: 'Suicide Call Back Service',
            ContactName: '',
            Email: '',
            Phone: '1300659467',
            CallForSupport: true,
            Order: 3,
            Address: '',
            Type: 'Organisation',
            Relationship: '',
            Image: '/images/avatars/providerDefaultAvatar.png',
            ImageType: '',
            ImageUrl: '',
            ContactAddress: '',
            ContactEmail: '',
            ContactPhone: ''
          }
        ];

        await Promise.all(
          defaultEmergencyNumbers.map(async numbers => {
            await callNetworkContactCreateApi(numbers);
          })
        );
        
        //Hammad Tahir - invitationFix - 050821 - Create session varaible when setting up new profile
        sessionStorage.setItem('newProfile','true')

        dispatch(redirectAction(history));
      });
  } catch (err) {
    history.push('/');//Jorge Avendano T43.3 270720 Redirects to the index page in case of any issue
    // dispatch(failed(err.toString()));
  }
};

export const redirectAction = (history: any): AppThunk => async dispatch => {
  try {
    if (
      sessionStorage.getItem('invitationId') !== null &&
      sessionStorage.getItem('invitationId') !== ''
    ) {
      //Hammad Tahir - invitationFix - 050821 - If profile setup from invitation delete invitation and empty session variables
      if(sessionStorage.getItem('newProfile') === 'true'){
        await callInvitationDelete(String(sessionStorage.getItem('invitationId')));
        await dispatch(fetchConnectionRequests());

        sessionStorage.removeItem('invitationId');
        sessionStorage.removeItem('inviterEmail');
        sessionStorage.removeItem('inviterName');
    
        history.push('/goals/current');
      }else{ //Hammad Tahir - invitationFix - End
        dispatch(
          acceptConnectRequest(
            sessionStorage.getItem('invitationId')!,
            sessionStorage.getItem('inviterEmail')!,
            sessionStorage.getItem('inviterName')!,
            () => { },
            sessionStorage.getItem('inviterAccountType')! === 'RelatedPerson'
              ? 'Person'
              : sessionStorage.getItem('inviterAccountType')! === 'Practitioner'
                ? 'Organisation'
                : 'Person',
            history
          )
        );
      }
    }
    // Jorge Avendano T39 130820 mailing redirection begin
    else if (sessionStorage.getItem('target') !== null &&
      sessionStorage.getItem('target') !== '') {
      let target = sessionStorage.getItem('target');
      //let targetComponent = '';
      //Goals redirection
      if (target == 'goals') {
        //redirection to a specific goal
        // if (sessionStorage.getItem('targetComponent') !== null &&
        //   sessionStorage.getItem('targetComponent') !== '') {
        //   targetComponent = sessionStorage.getItem('targetComponent')!;
        //   if (targetComponent == 'current') {
        //     if (sessionStorage.getItem('targetId') !== null &&
        //       sessionStorage.getItem('targetId') !== '') {
        //       history.push('/goals/current/' + sessionStorage.getItem('targetId') + '/review');
        //     }
        //   }
        // }
        history.push('/goals/current');
      }
      //Story
      else if (target == 'story') {
        history.push('/story');
      }
      //Safety
      else if (target == 'safety') {
        history.push('/safety');
      }
      //Network
      else if (target == 'network') {
        history.push('/networks/people');
      }
      else {
        history.push('/goals/current');
      }
    }
    // Jorge Avendano T39 end
    else {
      history.push('/goals/current');
    }
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

export const endSession = (): AppThunk => async dispatch => {
  try {
    authentication.signOut();
    sessionStorage.clear();
    dispatch(end());
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

//** API FUNCS */

export const callProfileReadApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  return axios.get('/Profile/Read/?contactType=935000000').then(response => {
    const profile: Profile = response.data;
    sessionStorage.setItem('UserId', profile.UserId);
    sessionStorage.setItem('SafetyPlanId', profile.SafetyPlanId);
    sessionStorage.setItem('FirstName', profile.FirstName);
    sessionStorage.setItem('Surname', profile.Surname);
    sessionStorage.setItem('DateOfBirth', profile.DateOfBirth);
    sessionStorage.setItem('UserEmail', profile.UserEmail);
    sessionStorage.setItem(
      'Avatar',
      profile.Image
        ? 'data:image/png;base64,' + profile.Image
        : '/images/avatars/defaultAvatar.svg'
    );
    sessionStorage.setItem('ContactId', profile.ContactId);
    sessionStorage.setItem('RecoveryPlanId', profile.RecoveryPlanId);
    sessionStorage.setItem('LoggedInAs',profile.ContactType) //Hammad Tahir - switchAccountFix - 190721
    return profile;
  });
};

export const callProfileSetupApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
    return axios.post(`/Profile/Setup/935000000/${sessionStorage.getItem('invitationId')}`); //Hammad Tahir - invitationFix2 - 050821 - Send inviation Id if profile setup from invitation
};

export const callProfileCheckApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  return axios.post('/Profile/Check', { name: "check" }, { timeout: 10000 });//Jorge Avendano T43.3 270720 Redirects to the index page in case of any issue
};
